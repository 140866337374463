<template>
  <div>
    <el-upload
      :action="`${api}/detailmarkettool-config-upload.html`"
      :on-remove="handleRemove"
      :on-success="uploadSuccess"
      :on-error="uploadError"
      :before-upload="beforeUpload"
      list-type="picture-card"
      :limit="1"
      :headers="{ token: state.token }"
      :accept="accept"
      class="avatar-uploader"
      :file-list="imgList || []"
      ref="upload"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
  </div>
</template>

<script>
import { nextTick, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { api } from '../../tools/config';
import { ElMessage } from 'element-plus';
export default {
  props: {
    url: {
      type: String,
    },
    accept: {
      type: String,
      default: 'image/*',
    },
  },
  setup(props, { emit }) {
    const { state } = useStore();
    const imgList = ref();
    const curImg = ref();

    let count = 0; // 记录监听 props 变化次数的变量
    watchEffect(() => {
      if (props.url && count === 0) {
        imgList.value = [{ url: props.url }];
        nextTick(() => {
          hideBtn();
        });
        count++;
      }
    });

    const beforeUpload = () => {
      hideBtn();
      count++;
    };

    const upload = ref();
    const uploadSuccess = res => {
      if (res.status === 0) {
        ElMessage.error(res.msg);
        upload.value.clearFiles();
        showBtn();
        return;
      }
      curImg.value = res.url;
      emit('success', curImg.value);
    };

    const handleRemove = () => {
      imgList.value = null;
      curImg.value = null;
      showBtn();
      emit('remove');
    };
    const uploadError = () => {
      showBtn();
      ElMessage.error('上传失败');
    };

    const showBtn = () => {
      document.querySelector('.el-upload.el-upload--picture-card').style.display = 'inline-block';
      setTimeout(() => {
        document.querySelector('.el-upload.el-upload--picture-card').style.opacity = 1;
      }, 1000);
    };
    const hideBtn = () => {
      document.querySelector('.el-upload.el-upload--picture-card').style.display = 'none';
      document.querySelector('.el-upload.el-upload--picture-card').style.opacity = 0;
    };

    return {
      uploadSuccess,
      handleRemove,
      uploadError,
      state,
      beforeUpload,
      curImg,
      api: api(),
      imgList,
      upload,
    };
  },
};
</script>

<style lang="scss">
.avatar-uploader {
  width: 70px;
  height: 70px;
  .el-upload--picture-card {
    width: 70px;
    height: 70px;
    line-height: 70px;
    transition: all 0.3s linear;

    i {
      font-size: 18px;
    }
  }
  .el-upload-list__item {
    width: 70px;
    height: 70px;
  }
  .el-upload-list--picture-card .el-upload-list__item-actions {
    .el-upload-list__item-preview {
      display: none;
    }
    .el-upload-list__item-delete {
      margin-left: 0;
    }
  }
}
</style>
