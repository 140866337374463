<template>
  <div class="custom">
    <el-form
      ref="form"
      label-position="top"
      label-width="80px"
      :model="formData"
      :rules="rules"
      size="medium"
    >
      <el-form-item label="显示内容" prop="content">
        <el-tooltip content="变量说明:{random}为数量区间" placement="right" effect="light">
          <i class="el-icon-question" style="position: absolute"></i>
        </el-tooltip>
        <el-input v-model="formData.content"></el-input>
      </el-form-item>
      <el-form-item label="图标" required>
        <el-form-item>
          <el-radio-group v-model="formData.param.iconType">
            <el-radio :label="1">推荐图标</el-radio>
            <el-radio :label="2">自定义图标</el-radio>
            <el-tooltip content="建议上传小于500KB的SVG图标" placement="right" effect="light">
              <i
                class="el-icon-question"
                style="position: static; transform: translate(-26px, 1px)"
              ></i>
            </el-tooltip>
          </el-radio-group>
        </el-form-item>
        <div class="icons-wrap">
          <div
            :class="['icon-item', formData.iconFont === item ? 'active' : 0]"
            v-for="item in iconData"
            :key="item"
            @click="selectIcon(item)"
            v-show="formData.param.iconType === 1"
          >
            <i :class="['detailmarkettool-iconfont', item]"></i>
          </div>
          <el-form-item prop="iconUrl" v-show="formData.param.iconType === 2">
            <MUploadImage
              @success="setImgUrl"
              @remove="removeIcon"
              :url="formData.iconUrl"
              accept=".svg"
            />
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="数量区间" required>
        <div class="two-inputs-wrap">
          <div>
            <el-form-item prop="param.section[0]">
              <el-input v-model.number="formData.param.section[0]"></el-input>
            </el-form-item>
          </div>
          <div class="connect-icon">~</div>
          <div>
            <el-form-item prop="param.section[1]">
              <el-input v-model.number="formData.param.section[1]"></el-input>
            </el-form-item>
          </div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { ref, watch, watchEffect } from 'vue';

import MUploadImage from '@/components/uploadImage/UploadImage';

export default {
  name: 'Custom',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    MUploadImage,
  },
  setup(props, { emit }) {
    const formData = ref({});
    watchEffect(() => {
      formData.value = JSON.parse(JSON.stringify(props.data));

      if (!props.data.icon) {
        // 图标不存在
        formData.value.iconFont = 'icon-xinpin';
        formData.value.iconUrl = null;
      } else if (props.data.param.iconType === 1) {
        // 图标存在，且iconType为1（即推荐图标）
        formData.value.iconFont = formData.value.icon;
      } else if (props.data.param.iconType === 2) {
        // 图标存在，且iconType为2（即本地图标）
        formData.value.iconUrl = formData.value.icon;
        formData.value.iconFont = 'icon-xinpin';
      }

      if (!formData.value.param) {
        formData.value.param = {
          section: [],
          iconType: 1,
        };
      }

      emit('dataChange', formData.value);
    });

    watch(formData.value, cur => {
      const data = JSON.parse(JSON.stringify(formData.value));
      delete data.iconUrl;
      delete data.iconFont;

      if (cur.param.iconType === 1) {
        data.icon = cur.iconFont;
      } else {
        data.icon = cur.iconUrl;
      }

      emit('dataChange', data);
    });

    // 上传图标
    const setImgUrl = url => {
      formData.value.iconUrl = url;
    };

    // 删除图标
    const removeIcon = () => {
      formData.value.iconUrl = '';
    };

    // 验证区间大小
    const validateSection = (rule, value, callback) => {
      if (/[A-z]/.test(value)) {
        callback(new Error('请输入正整数'));
      } else if (formData.value.param.section[0] > formData.value.param.section[1]) {
        callback(new Error('第一个输入框的值应小于第二个输入框'));
      } else {
        callback();
      }
    };
    // 验证是否有图标
    const hasUrl = (rule, value, callback) => {
      console.log('j');
      const { param, iconUrl } = formData.value;
      if (param.iconType === 2 && !iconUrl) {
        callback(new Error('请上传图标!'));
      } else {
        callback();
      }
    };

    const form = ref();
    // 验证
    const validate = callback => {
      form.value.validate(valid => {
        if (valid) {
          callback();
        }
      });
    };

    // 选择图标
    const selectIcon = icon => {
      formData.value.iconFont = icon;
    };

    return {
      formData,
      validate,
      form,
      setImgUrl,
      removeIcon,
      selectIcon,
      iconData: [
        'icon-xinpin',
        'icon-shangpin',
        'icon-yuangongjiangliwodejianglijiangpinwodelipin',
        'icon-shangpingouwudai',
        'icon-gouwuche3',
        'icon-changxiaoremai',
        'icon-feiji',
        'icon-lipin',
        'icon-remai',
        'icon-xinpin1',
        'icon-discount-coupon_icon',
        'icon-feiji1',
        'icon-xinpin2',
        'icon-zhekou',
        'icon-zhekou1',
        'icon-ico',
        'icon-remai1',
        'icon-shangpin1',
        'icon-shinshopxinpin',
        'icon-shinshoplipinqia',
        'icon-zhekouyouhui',
        'icon-zhekou2',
        'icon-lipinka',
        'icon-shangpin2',
        'icon-feiji2',
        'icon-gouwuche5',
        'icon-gouwuche6',
        'icon-feiji3',
        'icon-zhekou3',
      ],
      rules: {
        content: [{ required: true, whitespace: true, message: '请填写显示内容', trigger: 'blur' }],
        iconUrl: [
          {
            validator: hasUrl,
            trigger: 'blur',
          },
        ],
        'param.section[0]': [
          { required: true, message: '请填写数量区间', trigger: 'blur' },
          {
            validator: validateSection,
            trigger: 'blur',
          },
        ],
        'param.section[1]': [
          { required: true, message: '请填写数量区间', trigger: 'blur' },
          { type: 'number', min: 0, message: '请输入正整数', trigger: 'blur' },
          {
            validator: validateSection,
            trigger: 'blur',
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../statics/scss/iconfont';
@import '@/assets/scss/tool';
.icons-wrap {
  background-color: $formBg;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  margin-top: 16px;
  flex-wrap: wrap;

  .icon-item {
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 2px;
    border: solid 1px #d7dde3;
    margin: 10px 0;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 4px;
    box-sizing: border-box;
    transition: border-color 0.3s linear;

    &.active {
      border: 2px solid $theme;
      i.detailmarkettool-iconfont {
        color: $theme;
      }
    }
    i.detailmarkettool-iconfont {
      display: inline-block;
      font-size: 26px;
      color: #666666;
    }
  }
}
.two-inputs-wrap {
  display: flex;
  width: 450px;
  div {
    flex: 1;
  }
  .connect-icon {
    flex: 0 0 30px;
    display: flex;
    justify-content: center;
  }
}
.el-input {
  width: 100%;
  max-width: 500px;
}
.el-icon-question {
  position: absolute;
  top: -30px;
  font-size: 16px;
  left: 70px;
  color: #666666;
  cursor: pointer;
}
</style>
