<template>
  <div class="stock">
    <el-form
      ref="form"
      label-position="top"
      label-width="80px"
      :model="formData"
      :rules="rules"
      size="medium"
    >
      <el-form-item label="显示内容" prop="content">
        <el-tooltip
          content="变量说明:{random_stock}为随机库存区间"
          placement="right"
          effect="light"
        >
          <i class="el-icon-question" style="position: absolute"></i>
        </el-tooltip>
        <el-input v-model="formData.content"></el-input>
      </el-form-item>
      <el-form-item label="图标">
        <div class="icons-wrap">
          <div
            :class="['icon-item', formData.icon === item ? 'active' : 0]"
            v-for="item in iconData"
            :key="item"
            @click="selectIcon(item)"
          >
            <i :class="['detailmarkettool-iconfont', item]"></i>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="库存数量">
        <el-radio-group v-model="formData.param.stockType">
          <el-radio :label="1">真实库存</el-radio>
          <el-radio :label="2">随机库存</el-radio>
        </el-radio-group>
      </el-form-item>
      <div v-if="showTemplate" class="tem-wrap">
        <el-form-item label="随机区间" required>
          <el-tooltip content="库存不得大于1000" placement="right" effect="light">
            <i class="el-icon-question" style="position: absolute"></i>
          </el-tooltip>
          <div class="two-inputs-wrap">
            <div>
              <el-form-item prop="param.section[0]">
                <el-input v-model.number="formData.param.section[0]"></el-input>
              </el-form-item>
            </div>
            <div class="connect-icon">~</div>
            <div>
              <el-form-item prop="param.section[1]">
                <el-input v-model.number="formData.param.section[1]"></el-input>
              </el-form-item>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="刷新后库存减少">
          <el-select v-model="formData.param.reduceStocksNum" placeholder="">
            <el-option
              v-for="(item, index) in 21"
              :label="index"
              :value="index"
              :key="item"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import { computed, ref, watch, watchEffect } from 'vue';

export default {
  name: 'Stock',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const formData = ref({});
    watchEffect(() => {
      formData.value = JSON.parse(JSON.stringify(props.data));
      if (!formData.value.param) {
        formData.value.param = {
          stockType: 1,
          section: [],
          reduceStocksNum: 0,
        };
      }
      emit('dataChange', formData.value);
    });

    watch(formData.value, cur => {
      emit('dataChange', cur);
    });

    const showTemplate = computed(() => {
      if (formData.value.param) {
        return formData.value.param.stockType == 2;
      }
      return false;
    });

    // 选择图标
    const selectIcon = icon => {
      formData.value.icon = icon;
    };

    // 验证区间大小
    const validateSection = (rule, value, callback) => {
      if (/[A-z]/.test(value)) {
        callback(new Error('请输入正整数'));
      } else if (formData.value.param.section[0] > formData.value.param.section[1]) {
        callback(new Error('第一个输入框的值应小于第二个输入框'));
      } else {
        callback();
      }
    };

    const form = ref();
    // 验证
    const validate = callback => {
      form.value.validate(valid => {
        if (valid) {
          if (formData.value.param.stockType === 1) {
            formData.value.param.section = [];
            formData.value.param.reduceStocksNum = 0;
          }
          callback();
        }
      });
    };

    return {
      formData,
      selectIcon,
      showTemplate,
      validate,
      form,
      iconData: [
        'icon-kucunguanli',
        'icon-goodswhtotalStock',
        'icon-kucun',
        'icon-kucun_crm',
        'icon-kucunfenxi',
      ],
      rules: {
        content: [{ required: true, whitespace: true, message: '请填写显示内容', trigger: 'blur' }],
        'param.section[0]': [
          { required: true, message: '请填写随机区间', trigger: 'blur' },
          { type: 'number', min: 1, message: '请输入正整数', trigger: 'blur' },
          { type: 'number', max: 1000, message: '库存不得超过1000', trigger: 'blur' },
          {
            validator: validateSection,
            trigger: 'blur',
          },
        ],
        'param.section[1]': [
          { required: true, message: '请填写随机区间', trigger: 'blur' },
          { type: 'number', min: 1, message: '请输入正整数', trigger: 'blur' },
          { type: 'number', max: 1000, message: '库存不得超过1000', trigger: 'blur' },
          {
            validator: validateSection,
            trigger: 'blur',
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../statics/scss/iconfont';
@import '@/assets/scss/tool';
.icons-wrap {
  background-color: $formBg;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  .icon-item {
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 2px;
    border: solid 1px #d7dde3;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 4px;
    box-sizing: border-box;
    transition: border-color 0.3s linear;

    &.active {
      border: 2px solid $theme;
      i.detailmarkettool-iconfont {
        color: $theme;
      }
    }
    i.detailmarkettool-iconfont {
      display: inline-block;
      font-size: 26px;
      color: #666666;
    }
  }
}
.tem-wrap {
  background-color: $formBg;
  padding: 10px 20px;
}
.two-inputs-wrap {
  display: flex;
  width: 450px;
  div {
    flex: 1;
  }
  .connect-icon {
    flex: 0 0 30px;
    display: flex;
    justify-content: center;
  }
}
.el-input {
  width: 100%;
  max-width: 500px;
}
.el-select {
  width: 100%;
  max-width: 450px;
}
.el-icon-question {
  position: absolute;
  top: -30px;
  font-size: 16px;
  left: 70px;
  color: #666666;
  cursor: pointer;
}
</style>
